html {
  scroll-behavior: smooth;
}

body {
  font-family: "Times New Roman", sans-serif; /* Set a default font for the body */
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}

@import "~swiper/swiper-bundle.css";

.background_Home {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1)
    ),
    url("./assets/bg_Home.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align children to the start (left side) */
  align-items: center; /* Center items vertically */
  color: #ffffff;
}

.background_AboutUs {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1)
    ),
    url("./assets/bg_AboutUs.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
}

/* Content container styles */
.content-container {
  display: flex; /* Align logo and welcome message horizontally */
  align-items: center; /* Center items vertically */
  margin-left: 30px; /* Space from the left */
}

.logo-container {
  margin-right: 20px; /* Space between logo and text */
}

.logo {
  height: 400px; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
}

.welcome-message {
  text-align: left; /* Align text to the left */
}

/* Welcome message style */
.welcome-text {
  font-size: 36px; /* Adjust size for the Welcome message */
  margin: 0; /* Remove default margin */
  font-family: "Aboreto", sans-serif; /* Apply Aboreto font */
  color: white; /* Keep the original color (white) */
}

.welcome-message h2 {
  font-size: 80px; /* Adjust size for Audio Axis */
  margin: 0; /* Remove default margin */
  font-family: "Aboreto", sans-serif; /* Apply Aboreto font */
}

.axis-color {
  color: #ffa600; /* Color for "Axis" */
}

/* Navbar styles */
.navbar {
  position: fixed; /* Keep the navbar fixed */
  top: 0; /* Align it to the top */
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between; /* Space between brand and links */
  align-items: center; /* Center items vertically */
  padding: 15px 30px; /* Padding around the navbar */
  background-color: transparent; /* Make the background transparent */
  z-index: 1; /* Ensure the navbar is above the background */
}

.navbar-brand {
  color: white; /* Brand text color for "Audio" */
  font-size: 40px; /* Adjust font size as needed */
  font-family: "Aboreto", sans-serif; /* Apply Aboreto font */
}

.navbar-links {
  list-style: none; /* Remove default list styling */
  display: flex; /* Align items horizontally */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.navbar-links li {
  margin-left: 50px; /* Space between links */
}

.navbar-links a {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline from links */
  font-size: 18px; /* Adjust font size as needed */
  font-family: "Aboreto", sans-serif; /* Apply Aboreto font for links */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.navbar-links a:hover {
  color: #ffa600;
  font-weight: bold;/* Change color on hover for effect */
}

.button-container {
  position: absolute;
  top: 80%; /* Adjust this value to move the buttons up or down */
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  display: flex;
  gap: 50px; /* Space between the buttons */
}

.custom-button {
  border: 3px solid #ffa600;
  width: 300px; /* Set width for buttons */
  background-color: transparent; /* Transparent background */
  color: #ffa600; /* Text color */
  border-radius: 20px; /* Rounded corners */
  padding: 10px 20px; /* Padding around the text */
  font-size: 20px; /* Font size */
  cursor: pointer;
  font-family: "Times New Roman", sans-serif;
  transition: background-color 0.7s, color 0.7s, letter-spacing 0.3s; /* Smooth transitions */
}

.custom-button:hover {
  background-color: #ffa600; /* Change background on hover */
  color: white;
  font-weight: bold;
  letter-spacing: 2px; /* Change text color on hover */
}

.active-link {
  color: #ffa600; /* Color for selected link */
  font-weight: bold; /* Optional: Make it bold */
}

/* About Us Section Styles */
/* Ensure the About Us section uses Flexbox */
.background_AboutUs {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Center items vertically */
  justify-content: left; /* Center items horizontally */
  height: 100vh; /* Set height to fill the viewport */
}

/* Style the profile image */
.profile-image {
  margin-right: 100px;
  margin-left: 10%; /* Space between image and text */
}

.profile-image img {
  width: 350px; /* Set a specific width for the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Add rounded corners */
}

/* Style the text container */
.about-text {
  max-width: 800px; /* Limit the width of the text */
  text-align: justify;
  font-size: 25px; /* Align text to the justify */
}

.about-text {
  flex: 4; /* Take 2 parts of the available space */
}

.about-text h2 {
  color: #ffa600;
  font-family: Aboreto;
  font-size: 1cm; /* Change the heading color */
}

.background_Services {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1)
    ),
    url("./assets/bg_Services.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensures it fills the viewport */
  width: 100%;
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center; /* Center items vertically */
  color: #ffffff;
}

.services-text {
  margin-bottom: 10px !important; /* Space between the heading and the image slider */
  text-align: center; /* Center the heading */
  color: #ffa600; /* Match other section heading color */
  font-size: 0.5cm;
  font-family: Aboreto; /* Adjust font size as needed */
}

.image-slider {
  display: flex;
  overflow: hidden; /* Hide overflow */
  position: relative; /* Position relative for the animation */
  width: 100%; /* Full width of the container */
  height: 300px; /* Set height for images */
  margin: 0 auto; /* Center the slider */
  z-index: 1; /* Ensure images are above background */
}

.image-wrapper {
  display: flex; /* Align images in a row */
  transition: transform 0.5s ease; /* Smooth transition for sliding effect */
}

.image {
  flex: 0 0 calc(33.33% - 10px); /* Adjust for gap */
  position: relative; /* Positioning for image names */
  height: 100%; /* Full height of the container */
  margin: 0 5px;
  box-sizing: border-box;
}

.image img {
  width: 100%; /* Responsive width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
  filter: blur(2px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Shadow for depth */
}

.image-name {
  position: absolute; /* Position the name on top of the image */
  bottom: 10px; /* Space from the bottom */
  left: 50%; /* Center the name */
  transform: translateX(-50%); /* Adjust to center */
  width: 92%;
  background-color: rgba(0, 0, 0, 0.7); /* Background for better visibility */
  color: #ffa600; /* Text color */
  padding: 5px 10px; /* Padding for spacing */
  border-radius: 5px; /* Rounded corners for the name box */
  font-size: 1.5em; /* Font size */
  text-align: center; /* Center the text */
}

.background_Portfolio {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1)
    ),
    url("./assets/bg_Portfolio.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensures it fills the viewport */
  width: 100%;
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center; /* Center items vertically */
  color: #ffffff;
}

.portfolio-text {margin-top: 10px;
 /* Space between the heading and the image slider */
  text-align: center; /* Center the heading */
  color: #ffa600; /* Match other section heading color */
  font-size: 0.5cm; 
  font-family: Aboreto;/* Adjust font size as needed */
}
* {
  box-sizing: border-box;
}

.carousel {
  background: #eee;
}

.carousel-cell {
  margin-right: 20px;
  overflow: hidden;
}

.carousel-cell img {
  display: flex;
  height: 200px;
}

@media screen and (min-width: 768px) {
  .carousel-cell img {
    height: 400px;
  }
}

.background_Contactus {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1)
    ),
    url("./assets/bg_Contactus.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensures it fills the viewport */
  width: 100%;
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: center;
  align-items: center; /* Center items vertically */
  color: #ffffff;
}

.contactus-text {
  margin-top: 10px;
  margin-bottom: 3px !important; /* Space between the heading and the image slider */
  text-align: center; /* Center the heading */
  color: #ffa600; /* Match other section heading color */
  font-size: 0.5cm;
  font-family: Aboreto; /* Adjust font size as needed */
}

/* Container for the contact section */
/* Container for the contact section */
.contact-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  min-height: 13cm;
  height: 100%;
  width: 70%;
  background-color: rgba(
    240,
    240,
    240,
    0.2
  ); /* Light background with transparency */
  margin: 0 auto;
}

/* Contact box with 50% width and transparency */
.contact-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 13cm;
  width: 50%; /* Set the width to 50% of the container */
  max-width: 600px; /* Optional: set a max width */
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* White background with 50% transparency */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for the box */
  margin-left: auto; /* Align to the right */
  padding: 20px;
  box-sizing: border-box;
}

/* Flexbox for the contact info */
/* Contact info for both address and phone */
.contact-info {
  display: table;
  align-items: center;
  margin-left: 1.5cm; /* Space from the left */
  color: white; /* Text color for the contact info */
  margin-bottom: 50px; /* Space between contact info sections */
}

/* Style for the map, phone, and email icons */
.contact-info i {
  font-size: 14px;
  margin-top: 1cm; /* Vertical space from the top */
  margin-right: 10px; /* Space between icon and label */
}

/* Bold for the "Address:", "Let's Talk:", and "General Support:" labels */
.address-title,
.call-title,
.email-title {
  /* New class for email title */
  font-weight: 400;
  font-size: 20px;
  margin-right: 4px; /* Space between the label and text */
}

/* Dummy address, phone number, and email styling */
.dummy-address,
.dummy-phone-number,
.dummy-email {
  /* New class for dummy email */
  color: white;
  width: 400px; /* Set dummy address, phone number, and email text color */
}

.contact-title{
  font-size: 34px; /* Font size for title */
  font-weight: bold; /* Bold title */
  margin-bottom: 20px;
  text-align: center; /* Space below title */
}

.contact-form {
  display: flex; /* Flexbox for the form */
  flex-direction: column;
  align-items: center; /* Stack form elements */
  width: 100%; /* Full width of the contact box */
}

.form-group {
  margin-bottom: 15px; /* Space between form groups */
  width: 100%; /* Full width for form groups */
}

label {
  font-size: 19px; /* Font size for labels */
  font-weight: 500;
  color: #333; /* Label text color */
  display: block; /* Block display for labels */
  margin-bottom: 5px;
  font-weight: 800; /* Space below labels */
}

input {
  width: 100%; /* Full width for input fields */
  padding: 10px; /* Padding inside input fields */
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.6); /* Border style */
  border-radius: 15px; /* Rounded corners */
  font-size: 18px; /* Font size for input fields */
  box-sizing: border-box;
  font-family: 'Times New Roman', Times, serif; /* Include padding and border in width */
}

textarea {
  height: 100px;
  width: 100%; /* Set height for the message textarea */
  resize: vertical;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  box-sizing: border-box;
  font-family: 'Times New Roman', Times, serif;
}

.submit-button {
  padding: 10px 20px;
  width: 50%;
  justify-content: center;
  background-color: #4caf50; /* Green background */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size for the button */
}

.submit-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

* {
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.carousel-container {
  max-width: 1200px; /* Set maximum width */
  margin: auto; /* Center carousel */
  overflow: hidden; /* Hide overflow */
}

.carousel-item {
  width: 100%; /* Make sure each item takes full width */
  padding: 10px; /* Space around items */
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
}

.image-container {
  position: relative; /* Position relative for the overlay */
  width: 100%; /* Make the container responsive */
  overflow: hidden; /* Prevent overlay from overflowing */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Optional: Add background to fill empty space */
}

.carousel-item img {
  width: 100%; /* Makes the image responsive */
  height: 100%; /* Make the image cover the container height */
  object-fit: contain; /* Ensures the image fills the container without distortion */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Smooth transition */
  pointer-events: none; /* Prevent overlay from affecting image clicks */
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.movie-name {
  color: white; /* Text color */
  font-size: 24px; /* Adjust this size as needed */
  text-align: center; /* Center the text */
  font-weight: bold; /* Make text bold */
  padding: 10px; /* Add some padding around the text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Add a subtle shadow for better visibility */
}


/* Styles for custom arrows */
.slick-prev,
.slick-next {
  width: 30px; /* Width of arrows */
  height: 30px; /* Height of arrows */
  border-radius: 50%; /* Rounded arrows */
  display: flex; /* Center arrow icon */
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor */
  z-index: 1; /* Ensure arrows are above the images */
}

.slick-prev {
  left: 0; /* Position left arrow */
}

.slick-next {
  right: 0; /* Position right arrow */
}

